<template>
  <div class="edit-content">
    <Mural
      class="q-mb-md"
      route="home"
      :query="{}"
      :breadcrumbs="breadcrumbs"
      titleButton="Novo Post"
    >
      <div>
        <DefaultBtn
          titleButton="Salvar"
          @click.prevent.stop="onSubmit"
          icon="save"
        />
      </div>
    </Mural>
    <main class="full-height row justify-start items-around">
      <div class="row items-center profile-photo">
        <div>
          <div class="avatar-border">
            <q-icon
              v-if="formDataStudnet.image == null"
              name="account_circle"
              size="96px"
            />
            <q-img
              v-else
              class="avatar"
              :src="formDataStudnet.image"
              spinner-color="white"
            >
              <template v-slot:error>
                <q-icon class="avatar" name="account_circle" size="90px" />
              </template>
            </q-img>
          </div>
          <q-fab
            class="icon-photo"
            color="default-pink"
            icon="photo"
            direction="right"
            padding="xs"
          >
            <q-fab-action
              color="default-pink"
              @click="sendEventAddImage"
              size="xs"
              round
              external-label
            >
              <template v-slot:icon>
                <q-icon name="o_photo_camera" />
                <q-tooltip> Upload de foto </q-tooltip>
              </template>
            </q-fab-action>
            <q-fab-action
              color="default-pink"
              size="xs"
              round
              external-label
              :to="'avatars-gallery'"
            >
              <template v-slot:icon>
                <q-img :src="gallery_thumbnail" />
                <q-tooltip> Galeria de imagens </q-tooltip>
              </template>
            </q-fab-action>
          </q-fab>

          <input
            style="display: none"
            ref="refFileInput"
            @change="onEventFilePicked"
            type="file"
            name="upload"
            accept="image/*"
          />
        </div>
        <div class="q-ml-md">
          <h4>{{ nameUser }}</h4>
          <small>{{ typeUser }} </small>
        </div>
      </div>
      <form
        class="profile-data row full-width justify-around"
        @submit.prevent.stop="onSubmit"
      >
        <div class="col-3 q-ml-xl q-mr-md">
          <h6>Nome</h6>
          <q-input
            outlined
            dense
            disable
            type="text"
            bg-color="grey-3"
            color="default-pink"
            v-model="formDataStudnet.name"
          />
        </div>
        <div class="col-6 q-mr-md">
          <h6>Sobrenome</h6>
          <q-input
            outlined
            dense
            disable
            type="text"
            bg-color="grey-3"
            color="default-pink"
            v-model="formDataStudnet.lastname"
          />
        </div>
        <div class="col-6">
          <h6>Nome de Usuário</h6>
          <q-input
            outlined
            dense
            type="text"
            bg-color="grey-3"
            color="default-pink"
            v-model="formDataStudnet.nick_name"
          />
        </div>
        <!--<div class="col-5">
          <h6>Email</h6>
          <q-input
            outlined
            dense
            type="email"
            bg-color="grey-3"
            color="default-pink"
            v-model="formDataStudnet.email"
            :rules="[
              (val) => !!val || 'O campo email é obrigatório.',
              isValidEmail,
            ]"
          /> 
        </div>-->
      </form>
      <hr />
      <form
        class="profile-data-password full-width"
        @submit.prevent.stop="onSubmit"
      >
        <h5>Alterar senha</h5>

        <div class="profile-confirm-data flex col-12">
          <div class="col-3 q-mr-md">
            <h6>Nova senha</h6>
            <q-input
              outlined
              dense
              required
              :type="isPwd ? 'text' : 'password'"
              bg-color="grey-3"
              color="default-pink"
              v-model="formDataStudnet.new_password"
            >
              <template v-slot:append>
                <q-icon
                  :name="isPwd ? 'visibility' : 'visibility_off'"
                  class="cursor-pointer"
                  @click="isPwd = !isPwd"
                />
              </template>
            </q-input>
          </div>
          <div class="col-3">
            <h6>Confirmar senha</h6>
            <q-input
              outlined
              dense
              :type="isPwd ? 'text' : 'password'"
              bg-color="grey-3"
              color="default-pink"
              v-model="formDataStudnet.confirm_new_password"
              ><template v-slot:append>
                <q-icon
                  :name="isPwd ? 'visibility' : 'visibility_off'"
                  class="cursor-pointer"
                  @click="isPwd = !isPwd"
                /> </template
            ></q-input>
          </div>
          <div class="flex items-end justify-center col-6 btn-submit">
            <q-btn
              class="col-5"
              outline
              required
              type="submit"
              text-color="default-pink"
              label="Altera Senha"
            />
          </div>
        </div>
      </form>
      <q-inner-loading
        :showing="isLoading"
        label="Por favor aguarde..."
        label-class="text-teal"
        label-style="font-size: 1.1em"
      >
        <div class="collumn justify-center q-my-md">
          <div style="height: auto; width: 50px; margin: auto">
            <LoadingIcon />
          </div>
          <label>Por favor aguarde...</label>
        </div>
      </q-inner-loading>
    </main>
  </div>
</template>

<script>
import Mural from "@/components/Mural.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import TaskProgress from "@/components/TaskProgress.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";

import gallery_thumbnail from "@/assets/icons/gallery_thumbnail.svg";

/* Services */
import { ENV } from "@/utils/env";
import UserServices from "@/services/UserServices";
import blacklistVerification from "@/utils/companySettings/blacklistVerification";

/* Vue */
import { useRouter, useRoute } from "vue-router";

import { ref, reactive, onMounted } from "vue";
import { useQuasar } from "quasar";
import { useStore } from "vuex";

export default {
  name: "EditUser",
  components: {
    Mural,
    DefaultBtn,
    TaskProgress,
    LoadingIcon,
  },

  setup() {
    const store = useStore();

    const $q = useQuasar();
    const isLoading = ref(false);
    const refFileInput = ref(null);
    let breadcrumbs = ref(["Editar Perfil"]);
    let nameUser = ref("");
    let typeUser = ref("");
    let formDataStudnet = reactive({
      name: "",
      lastname: "",
      nick_name: "",
      email: "",
      password: "",
      new_password: "",
      confirm_new_password: "",
      image: "",
      file: null,
      groups: [],
      role_id: 0,
      Lider: 0,
      Company_Id: 0,
      status: 1,
      isLider: false,
      avatar_location: "",
      master: 0,
      email_valid: null,
    });
    let url_aws_bucket = ENV.URL_AWS_BUCKET;
    let UserService = new UserServices();
    let router = new useRouter();
    let groups = ref([]);
    let isPwd = ref(false);

    async function _getUserData() {
      let dataProfileUser = await UserService.getUserbyId(
        store.state.userdata.id
      );

      groups = dataProfileUser.data.groups.map((group) => {
        return group.group;
      });

      store.state.userdata.avatar_location =
        dataProfileUser.data.avatar_location;
      store.state.userdata.first_name = dataProfileUser.data.first_name;
      store.state.userdata.last_name = dataProfileUser.data.last_name;

      store.commit("setUserData", store.state.userdata);

      nameUser.value =
        dataProfileUser.data.first_name + " " + dataProfileUser.data.last_name;
      typeUser.value = store.state.userdata.role;

      formDataStudnet.name = dataProfileUser.data.first_name;
      formDataStudnet.lastname = dataProfileUser.data.last_name;
      formDataStudnet.nick_name = dataProfileUser.data.nick_name;
      formDataStudnet.email = dataProfileUser.data.email;
      formDataStudnet.user_group_id = dataProfileUser.data.user_group_id;
      formDataStudnet.role_id = dataProfileUser.data.role_id;
      formDataStudnet.Lider = dataProfileUser.data.lider;
      formDataStudnet.Company_Id = dataProfileUser.data.company_Id;
      formDataStudnet.image =
        url_aws_bucket + dataProfileUser.data.avatar_location;
      formDataStudnet.groups = groups;
      formDataStudnet.isLider = dataProfileUser.data.is_leader;
      formDataStudnet.avatar_location = dataProfileUser.data.avatar_location;
      formDataStudnet.master = dataProfileUser.data.master;
      formDataStudnet.email_valid = dataProfileUser.data.email_valid;
    }

    function isValidEmail(val) {
      const emailPattern =
        /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
      return emailPattern.test(val) || "Email inválido";
    }

    const onEventFilePicked = (event) => {
      const files = event.target.files;
      //image.val = files[0];
      const filename = files[0].name;
      formDataStudnet.file = files[0];
      if (filename.lastIndexOf(".") <= 0) {
        return alert("Por favor adicione um arquivo válido");
      }

      const fileReader = new FileReader();
      fileReader.addEventListener("load", (event) => {
        formDataStudnet.image = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
    };

    const sendEventAddImage = () => {
      refFileInput.value.click();
    };

    async function onSubmit() {
      if (
        blacklistVerification(
          formDataStudnet.nick_name,
          "Seu usuário infringe as regras sobre conteúdo e comportamento, favor verificar o conteúdo e tentar novamente"
        )
      ) {
        return;
      }
      isLoading.value = true;
      if (
        formDataStudnet.password != "" ||
        formDataStudnet.new_password != ""
      ) {
        if (
          formDataStudnet.new_password != formDataStudnet.confirm_new_password
        ) {
          $q.notify({
            message: "As senhas não conferem",
            color: "red-9",
            position: "top",
          });
          isLoading.value = false;
          return;
        }
      }

      const formData = new FormData();
      formData.append("First_name", formDataStudnet.name);
      formData.append("Last_name", formDataStudnet.lastname);
      formData.append("Nick_name", formDataStudnet.nick_name);
      formData.append("Lider", formDataStudnet.Lider);
      formData.append("Role_id", formDataStudnet.role_id);
      formData.append("Company_Id", formDataStudnet.Company_Id);
      formData.append("Status", formDataStudnet.status);
      formData.append("Email", formDataStudnet.email);
      formData.append("Email_valid", formDataStudnet.email_valid);
      formData.append("Master", formDataStudnet.master ? 1 : 0);

      if (
        formDataStudnet.file == null &&
        formDataStudnet.avatar_location != ""
      ) {
        formData.append("Avatar_type", null);
        formData.append("Avatar_location", formDataStudnet.avatar_location);
      } else {
        formData.append("Avatar_type", null);
        formData.append("Avatar_location", null);
      }

      formData.append("Image", formDataStudnet.file ?? null);
      formData.append("Password", formDataStudnet.new_password);
      formData.append(
        "Password_confirmed",
        formDataStudnet.confirm_new_password
      );
      formDataStudnet.groups.forEach((group) => {
        formData.append("Groups[]", group);
      });

      formData.append("is_leader", formDataStudnet.isLider);

      await UserService.updateUserbyId(store.state.userdata.id, formData)
        .then(async (result) => {
          $q.notify({
            message: "Dados alterados com sucesso",
            color: "green-9",
            position: "top",
          });
        })
        .catch((e) => {
          $q.notify({
            message: "Erro ao atualizar dados.",
            color: "red-9",
            position: "top",
          });
        });

      // if (response.status == 200) {
      //   //

      // } else {
      //   $q.notify({
      //     message: "Erro ao atulizar dados.",
      //     color: "red-9",
      //     position: "top",
      //   });
      // }

      isLoading.value = false;
      // .then((response) => {
      //   $q.notify({
      //     message: "Dados alterados com sucesso",
      //     color: "green-9",
      //     position: "top",
      //   });
      //   _getUserData();
      //   //router.go()
      // })
      // .catch(() => {
      //   this.$q.notify({
      //     message: "Erro ao atulizar dados.",
      //     color: "red-9",
      //     position: "top",
      //   });
      // });
    }

    onMounted(() => {
      _getUserData();
    });

    return {
      breadcrumbs,
      nameUser,
      typeUser,
      formDataStudnet,
      isValidEmail,
      isLoading,
      refFileInput,
      onSubmit,
      url_aws_bucket,
      onEventFilePicked,
      sendEventAddImage,
      gallery_thumbnail,
      isPwd,
    };
  },
};
</script>

<style lang="scss" scoped>
.edit-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 95vh;
  overflow: auto;
  padding: 2%;

  main {
    overflow: auto;

    hr {
      width: 100%;
      height: 1px;
      min-height: 1px;
      background: #e5e5e5;
      border: none;
      margin-bottom: 0.5rem;
    }
  }
  @media (min-width: 800px) {
    flex-direction: column;
  }
}

.profile-photo {
  > div {
    position: relative;

    .icon-photo {
      position: absolute;
      bottom: 0;
      right: 0;
      background: #fe5268;
      color: #fff;
    }

    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 2rem;
      line-height: 39px;
      color: #000000;
    }
    small {
      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      line-height: 20px;
      color: #00000073;
    }
  }
}

.avatar-border {
  overflow: hidden;
  height: 93px;
  width: 93px;
  padding: 3px;
  border-radius: 93px;
  position: relative;
}
.avatar {
  height: 95%;
  max-width: 95%;
  border-radius: 100%;
  position: absolute;
  left: 1.5%;
  top: 2.5%;
}

.profile-data,
.profile-password,
.profile-confirm-data {
  h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 0.87rem;
    line-height: 17px;
    color: #00000073;
  }
}

.profile-data {
  flex-direction: column;
  justify-content: start;
  div {
    width: 90%;
  }

  @media (min-width: 800px) {
    flex-direction: row;

    div {
      width: 25%;
    }
    .profile-data:last-child {
      width: 41.67%;
    }
  }
}

.profile-data-password {
  display: flex;
  flex-direction: column;

  padding: 0%;
  height: 40%;
  h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #00000099;
  }

  @media (min-width: 800px) {
    padding: 0 4%;
    div {
      width: auto;
    }
  }
}

.profile-password,
.profile-confirm-data {
  div {
    width: 90%;
  }

  @media (min-width: 800px) {
    div {
      width: auto;
    }
  }
}

.profile-confirm-data {
  .btn-submit {
    width: 90%;
    height: 35%;

    button {
      width: 50%;
    }

    @media (min-width: 800px) {
      width: 50%;
      height: 66%;

      button {
        width: 50%;
      }
    }
  }
}
</style>
