<template>
  <div class="flex row items-center justify-center">
    <q-circular-progress
      show-value
      font-size="12px"
      :value="porcent"
      size="50px"
      :thickness="0.15"
      color="blue"
      class="q-ma-md"
    >
      {{value}}
    </q-circular-progress>
    <div class="content-task">
      <h6>{{title}}</h6>
      <p>{{decription}}</p>
    </div>
  </div>
</template>


<script>
export default {
  name: "TaskProgress",
  props:{
    title:{
      type:String,
      default:"Treinamentos ministrados"
    },
    decription:{
      type:String,
      default:"Resultado de dados baseados em visualização"
    },
    value:{
      type:String,
      default:"08"
    },
    porcent:{
      type:Number,
      default: 0
    }
  }
};
</script>

<style lang="scss" scoped>


.content-task{
  /* max-width: 50%; */

  h6{
    font-style: normal;
    font-weight: 600;
    font-size: 0.8rem;
    color: #5C6FFF;
    line-height: 1rem;
  }
  p{
    font-style: normal;
    font-weight: 500;
    font-size: 0.7rem;
    color: #5C6FFF;
  }
}
</style>