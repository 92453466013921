/* Utils */
import AlertsClass from "@/utils/Notifications/AlertsClass";

const Alerts = new AlertsClass();

export default function blacklistVerification(
  comment,
  contentMessage = "O seu comentário infringe as regras sobre conteúdo e comportamento, favor verificar o conteúdo e tentar novamente."
) {
  const blacklists = JSON.parse(localStorage.getItem("vuex")).blacklist;
  let filter = blacklists.filter((blacklist) => {
    if (comment != null) {
      if (comment.toLowerCase().includes(blacklist.toLocaleLowerCase())) {
        return blacklist;
      }
    }
  });
  if (filter.length > 0) {
    Alerts.alertOf(`${contentMessage}`);
    return true;
  }
  return false;
}
